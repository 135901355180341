import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
 
@Injectable({
  providedIn: 'root'
})
export class ScreensizeService {
 
  private isDesktop = new BehaviorSubject<boolean>(false)
 
  constructor() { }
 
  onResize(size: any) {    
    if (size < 992) {
      this.isDesktop.next(false)
    } else {
      this.isDesktop.next(true)
    }
  }
 
  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged())
  }

  static switchToDesktop(previous: boolean, current: boolean) : boolean {
    if (previous && !current) {
      window.location.reload();
    }
    return current
  }
}